<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tw-grid tw-grid-cols-[auto,1fr] tw-gap-10">
    <SettingItem>
      <template #title>
        <a :href="passwordSetting.hash" role="link" class="tw-text-base">
          {{ passwordSetting.meta.title }}
        </a>
      </template>
      <template #content>
        <div>
          <fieldset class="tw-w-80">
            <v-text-field placeholder="Current password" type="password" variant="outlined" />
            <v-text-field placeholder="Repeat password" type="password" variant="outlined" />
          </fieldset>
          <!-- CTAs -->
          <v-btn color="yellow" size="large" rounded>
            {{ $t(`PI5['account.save']`) }}
          </v-btn>
        </div>
      </template>
    </SettingItem>
  </div>
</template>

<script lang="ts" setup>
//
</script>

<script lang="ts">
import { settingsRouteNames } from '@/views/settings/routes';
import SettingItem from '@/views/settings/components/settingItem.vue';

const passwordSetting = {
  hash: '#password',
  name: settingsRouteNames.account,
  meta: {
    title: 'Change password'
  }
};
export const accountSettingSearchRoutes = [passwordSetting];
</script>
