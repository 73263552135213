import { createRouter, createWebHistory } from 'vue-router';
import { useAccountStore } from '@/stores/account';
import { useAuth } from '@/composables/useAuth';
import NotFound from '@/components/NotFound.vue';
import { searchRoutes } from '@/views/settings/searchRoutes';
import type { RouteRecordNormalized } from 'vue-router';

type SearchRoute = Partial<RouteRecordNormalized> & { hash?: string };

const routes: RouteRecordNormalized[] = [];

const routeModules: Record<string, { default: RouteRecordNormalized | RouteRecordNormalized[] }> =
  import.meta.glob('@/views/**/routes.ts', { eager: true });

for (const path in routeModules) {
  const module = routeModules[path];
  if (Array.isArray(module.default)) {
    routes.push(...module.default);
  } else {
    routes.push(module.default);
  }
}

routes.push({
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  meta: { layout: 'content', requiresAuth: false },
  // @ts-ignore -- [Type error] should work properly according to doc (https://router.vuejs.org/guide/essentials/dynamic-matching.html#Catch-all-404-Not-found-Route)
  component: NotFound
});

const allSearchRoutes: Array<SearchRoute> = [];

function flattenRoute(route: RouteRecordNormalized) {
  const flattened = [];
  if (route?.meta?.title) {
    flattened.push(route);
  }
  route.children?.forEach((child) => {
    flattened.push(...flattenRoute(child as RouteRecordNormalized));
  });
  return flattened;
}

routes.forEach((route) => {
  allSearchRoutes.push(...flattenRoute(route));
});

searchRoutes.forEach((route) => {
  if (!route?.meta?.title) return;
  allSearchRoutes.push(route);
});

function getRouteTitle(route: SearchRoute) {
  if (route.hash) {
    const r = allSearchRoutes.find((r) => r.name === route.name && !r.hash);
    return `${r?.meta?.title} → ${route.meta?.title}`;
  }
  return route.meta?.title || route.name;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  }
});

router.beforeEach(async (to) => {
  const account = useAccountStore();
  const auth = useAuth();

  if (account.authenticated === null) {
    await auth.checkCurrentSession();
  }

  if (account.authenticated && account.me === null) {
    await auth.getUserAccess();
  }

  if (to.name === 'login' && account.authenticated) {
    return { name: 'dashboard' };
  }

  // white listed pages
  if (to.meta.requiresAuth === false) {
    return true;
  }

  // required log in pages
  if (!account.authenticated) {
    return { name: 'login', query: { redirectTo: to.fullPath } };
  }
});

export { allSearchRoutes, getRouteTitle };

export default router;
