import gql from 'graphql-tag';

export const CURRENT_USER_QUERY_STRING = `
  query user {
    me {
      access {
        capacity {
          canViewOrderSummary
          aggregateValueTypes
          hasAccess
          categories
          factories
          planningTypes
        }
        order {
          readOrderTypes
          writeOrderTypes
          writeTestOrderTypes
          restrictedOrderFields
          restrictedOrderLineFields
        }
        statusMessage {
          canManageStatusMessages
        }
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  ${CURRENT_USER_QUERY_STRING}
`;
