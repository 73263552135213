<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tw-grid tw-grid-cols-[auto,1fr] tw-gap-10">
    <SettingItem>
      <template #title>
        <a :href="profileSetting.hash" role="link" class="tw-mt-4 tw-text-base">
          {{ profileSetting.meta.title }}
        </a>
      </template>
      <template #content>
        <div>
          <button @click="profileInput?.click()" type="button">
            <UserCircleIcon class="tw-size-40" />
            <v-file-input ref="profileInput" class="tw-hidden" />
          </button>
          <p class="tw-italic tw-text-left">Select avatar - JPG, PNG</p>
        </div>
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
          Email
          <EnvelopeIcon class="tw-size-5" />
        </span>
      </template>
      <template #content>
        <span class="tw-text-base">
          {{ accountStore.user?.attributes?.email }}
        </span>
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
          <a :href="languageSetting.hash" role="link"> {{ languageSetting.meta.title }} </a>
          <GlobeAltIcon class="tw-size-5" />
        </span>
      </template>
      <template #content>
        <LanguageSelector
          data-testid="languageSelector"
          :list-props="{ density: 'compact' }"
          class="tw-w-40"
          variant="outlined"
          hide-details
        />
      </template>
    </SettingItem>
    <!--  -->
    <SettingItem>
      <template #title>
        <span class="tw-text-base tw-inline-flex tw-items-center tw-gap-2">
          <a :href="themeSetting.hash" role="link"> {{ themeSetting.meta.title }} </a>
          <span v-if="!theme.current.value.dark" data-testid="lightThemeIcon">
            <SunIcon class="tw-size-5" />
          </span>
          <span v-else data-testid="darkThemeIcon">
            <MoonIcon class="tw-size-5" />
          </span>
        </span>
      </template>
      <template #content>
        <v-btn-toggle
          :modelValue="theme.global.name.value"
          @update:modelValue="changeTheme"
          variant="outlined"
          mandatory
        >
          <v-btn v-for="themeItem in themes" :key="themeItem.value" :value="themeItem.value">
            {{ themeItem.title }}
          </v-btn>
        </v-btn-toggle>
      </template>
    </SettingItem>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
  GlobeAltIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon,
  EnvelopeIcon
} from '@heroicons/vue/24/outline';
import LanguageSelector from '@/components/Layout/components/LanguageSelector.vue';
import { useThemeSetting } from '@/composables/useThemeSetting';
import { useAccountStore } from '@/stores/account';
import SettingItem from '@/views/settings/components/settingItem.vue';

const accountStore = useAccountStore();

const { themes, changeTheme, theme } = useThemeSetting();
const profileInput = ref<HTMLInputElement | null>(null);
</script>

<script lang="ts">
import { settingsRouteNames } from '@/views/settings/routes';

const profileSetting = {
  hash: '#profile',
  name: settingsRouteNames.general,
  meta: { title: 'Profile' }
};
const themeSetting = {
  hash: '#theme',
  name: settingsRouteNames.general,
  meta: { title: 'Theme' }
};
const languageSetting = {
  hash: '#language',
  name: settingsRouteNames.general,
  meta: { title: 'Language' }
};
export const generalSettingSearchRoutes = [profileSetting, themeSetting, languageSetting];
</script>
