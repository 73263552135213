/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

// Composables
import { createVuetify } from 'vuetify';
import { useLocalStorage } from '@vueuse/core';
import { colors } from '../../tailwind.config.js';

const mq = window.matchMedia('(prefers-color-scheme: dark)');
const themeStorage = useLocalStorage('theme', mq.matches ? 'dark' : 'light');

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export const vuetify = createVuetify({
  defaults: {
    global: {
      density: 'compact',
      transition: false,
      reverseTransition: false
    },
    VBtn: {
      density: 'default'
    },
    VChip: {
      density: 'default'
    }
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: themeStorage.value,
    themes: {
      light: {
        dark: false,
        colors: {
          background: colors.grey[400],
          surface: colors.grey[200],
          primary: colors.primary,
          'on-surface-variant': colors.primary
        }
      },
      dark: {
        dark: true,
        colors: {
          primary: colors.primary,
          background: colors.grey[900],
          surface: colors.grey[1000],
          'surface-variant': colors.grey[900],
          'on-surface-variant': colors.primary
        }
      }
    }
  }
});

mq.addEventListener('change', (e) => {
  vuetify.theme.global.name.value = e.matches ? 'dark' : 'light';
});
