import gql from 'graphql-tag';
import { CUSTOMER_FIELDS, ORDER_FIELDS, ORDER_DOCUMENT_FIELDS } from './fragments';
import { PAGE_INFO_FIELDS } from '@/gql/fragments';

export const GET_USER_FAVORITES = gql`
  query UserFavorites {
    me {
      favorites {
        id
        item {
          ... on Order {
            id
            orderNumber
            orderType
            projectName
            createdAt
            updatedAt
            customer {
              ...CustomerFields
            }
            quoteCustomers {
              ...CustomerFields
            }
          }
        }
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const ORDER_COUNT_QUERY = gql`
  query orders($filters: OrderFilterInput!, $first: Int, $after: String) {
    orders(filter: $filters, first: $first, after: $after) {
      ... on OrdersConnection {
        totalCount
      }
    }
  }
`;

export const GET_ORDER_QUERY = gql`
  query order($id: ID!, $documentFilters: OrderDocumentFilterInput!) {
    order(id: $id) {
      ...OrderFields
      documents(filter: $documentFilters) {
        ...OrderDocumentFields
      }
    }
  }
  ${ORDER_FIELDS}
  ${ORDER_DOCUMENT_FIELDS}
`;

export const SEARCH_ORDER_QUERY = gql`
  query orders(
    $filters: OrderFilterInput!
    $sort: [OrdersSortTermInput!]
    $first: Int
    $after: String
  ) {
    orders(filter: $filters, sort: $sort, first: $first, after: $after) {
      ... on OrdersConnection {
        nodes {
          ...OrderFields
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${ORDER_FIELDS}
  ${PAGE_INFO_FIELDS}
`;

export const SEARCH_CUSTOMERS_QUERY = gql`
  query Customers($searchTerm: String!, $first: Int) {
    customers(filter: { searchTerm: $searchTerm }, first: $first) {
      nodes {
        ...CustomerFields
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const SEARCH_USERS_QUERY = gql`
  query User($searchTerm: String!, $first: Int) {
    users(filter: { searchTerm: $searchTerm }, first: $first) {
      nodes {
        firstName
        id
        lastName
        username
        customer {
          ...CustomerFields
        }
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;
