import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';

export function useThemeSetting() {
  const { t } = useI18n();
  const theme = useTheme();
  const themeStorage = useLocalStorage('theme', theme.global.name.value);

  const backgroundColors = computed(() => {
    return theme.current.value.dark ? 'theme-background' : 'theme-surface';
  });

  const themes = computed(() => [
    { title: t('PI5.theme_light'), value: 'light' },
    { title: t('PI5.theme_dark'), value: 'dark' }
  ]);

  function changeTheme(newTheme: string) {
    theme.global.name.value = newTheme;
    themeStorage.value = theme.global.name.value;
  }

  return {
    backgroundColors,
    changeTheme,
    theme,
    themes
  };
}
